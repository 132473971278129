type TPhoneMasks = {
    [key: string]: string;
};

const generalPhoneMask = String.raw`[0-9]{3}\-[0-9]{2}\-[0-9]{2}`;

export const phoneMasks: TPhoneMasks = {
    россия: String.raw`\+7 \([0-7,9][0-9]{2}\) ${generalPhoneMask}`,
    украина: String.raw`\+380 \([0-9]{2}\) ${generalPhoneMask}`,
    казахстан: String.raw`\+7 \([0-9]{3}\) ${generalPhoneMask}`,
    армения: String.raw`\+374 \([0-9]{3}\) ${generalPhoneMask}`,
    беларусь: String.raw`\+375 \([0-9]{2}\) ${generalPhoneMask}`,
};
