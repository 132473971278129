export const FormRegExs = {
    Punctuation: /\p{P}+/gu,
    Cyrillic: /^\p{sc=Cyrillic}+/gu,
    Latin: /^\p{sc=Latin}+/gu,
};

export const getComposedRegex = (...regexes: RegExp[]) =>
    new RegExp(regexes.map((regex) => regex.source).join('|'));

export const allowRussianInput = (str: string) => str.match(/[а-яА-Яё\W]/);
