export const required = (value: string) => !!value;

export const email = (value: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(value.trim());

export const phone = (value: string, mask: string): boolean => {
    const regexString = mask.replace(/9/g, '\\d');

    const regex = new RegExp(`^${regexString}$`);

    return regex.test(value);
};

export const texts = {
    required: {
        countryTitle: 'Выберите страну',
        cityTitle: 'Выберите город',
        cityTitleNoRussia: 'Введите город',
        cityRegion: 'Выберите область',
        address: 'Введите адрес доставки',
        userName: 'Введите ФИО',
        deliveryPoint: 'Не выбран пункт выдачи товара',
        postcode: 'Введите почтовый индекс',
        phone: 'Введите номер телефона',
        email: 'Введите адрес эл.почты',
    },
    notValid: {
        email: 'Неправильный формат почты',
        phone: 'Неправильный формат телефона',
    },
    cyrillicAllowed: {
        cyrillic: 'Только Кириллица',
        latin: 'Только Латиница',
    },
    order: 'При оформлении заказа произошла ошибка',
    orderDelivery:
        'Выбранный способ доставки недоступен, пожалуйста, выберите другой',
} as const;
